:root {
  --techPurple: #818cf8;
  --cyan-100: #cffafe;
  --bluish: #1a1d23;
  --bluish2: #161616;
  --orengish: #ffcc99;
}

html {
  scroll-behavior: smooth;
}

.hidden-content {
  display: none;
}

/* Section 1 */

/* Github logo */
.github-logo {
  max-width: 2rem;
  display: inline-block;
  margin-left: 1.3rem;
  padding-bottom: 1.3rem;
}

@media (max-width: 460px) {
  .github-logo {
    margin: 0;
    display: block;
  }
}
/*////////// Hamburger ////////////*/

.menu {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu > li {
  margin: 0 1rem;
  overflow: hidden;
}

.menu-button-container {
  display: none;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#menu-toggle {
  display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: var(--cyan-100);
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.menu-button::before {
  content: "";
  margin-top: -8px;
}

.menu-button::after {
  content: "";
  margin-top: 8px;
}

#menu-toggle:checked + .menu-button-container .menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#menu-toggle:checked + .menu-button-container .menu-button {
  background: rgba(255, 255, 255, 0);
}

#menu-toggle:checked + .menu-button-container .menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}

@media (max-width: 768px) {
  .menu-button-container {
    display: flex;
  }
  .menu {
    position: absolute;
    top: 0;
    margin-top: 4rem;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  #menu-toggle ~ .menu li {
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  #menu-toggle:checked ~ .menu li {
    height: 5.5rem;
    padding: 0;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
    align-items: center;
  }

  #menu-toggle:checked ~ .menu li a {
    color: var(--orengish);
  }

  .menu > li {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0.5em 0;
    width: 100%;
    color: var(--orengish);
    background-color: var(--bluish2);
  }
}

/* ///////// Hero text container ///////// */

/* cubic-bezier(0.46,0.03,0.52,0.96);  */
#hero-text {
  transition-timing-function: cubic-bezier(0.46, 0.03, 0.52, 0.96);
  animation-name: fadeInUp;
  animation-duration: 1s;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

/* ////////// Hero man container //////// */
#hero-man-container {
  transition-timing-function: cubic-bezier(0.46, 0.03, 0.52, 0.96);
  animation-name: fadeInLeft;
  animation-duration: 1s;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(2rem);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

/*//////////// Swiper /////////////*/

.swiper {
  overflow: hidden;
  position: relative;
  /* height: 55rem; */
}

.swiper-pagination {
  position: relative;
  display: block;
  margin-top: 2rem;
}

/* @media only screen and (min-width: 1024px) {
  #swiper-container {
    height: 50rem;
  }
} */

.swiper-button-next,
.swiper-button-prev,
.swiper-button-next::after,
.swiper-button-prev::after {
  display: none;
}

/* @media only screen and (min-width: 360px) {
  .swiper {
    height: 48rem;
  }
}

@media only screen and (min-width: 460px) {
  .swiper {
    height: 48rem;
  }
}

@media only screen and (min-width: 550px) {
  .swiper {
    height: 50rem;
  }
}

@media only screen and (min-width: 768px) {
  .swiper {
    height: 52rem;
  }
}

@media only screen and (min-width: 1024px) {
  .swiper {
    height: 44rem;
  }
}

@media only screen and (min-width: 1280px) {
  .swiper {
    height: 40rem;
  }
}

@media only screen and (min-width: 1536px) {
  .swiper {
    height: 43rem;
  }
}

@media only screen and (min-width: 1669px) {
  .swiper {
    height: 45rem;
  }
} */

/* Swiper custom button */

#custom-button-container {
  margin-top: 1rem;
}

#custom-button-next {
  margin-left: 2rem;
  font-size: 3rem;
  margin-bottom: 3rem;
}

#custom-button-prev {
  margin-right: 2rem;
  font-size: 3rem;
  margin-bottom: 3rem;
}

/*/////////// Project container //////////*/
#project-outer-container {
  width: 100% !important;
}

.project-container {
  /* From https://css.glass */
  background: rgba(146, 157, 171, 0.294);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.project-image {
  margin: auto;
}

/*/////////// Background gradient ///////////*/
#portfolio-bg {
  background-color: #c0bdf9;
  background-image: linear-gradient(0deg, #c0bdf9 0%, #fcf6e6 100%);
}

/*/////////// Fontawesome //////////*/
.fa-brands {
  color: #99ccff;
}

.fa-envelope {
  color: #99ccff;
}

.fa-arrow-right {
  color: #007aff;
}

.fa-arrow-left {
  color: #007aff;
}

/*/////////// Tech Icons  //////////*/
div > svg {
  fill: var(--techPurple);
  height: 50%;
  width: 50%;
}
